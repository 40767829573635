import React from 'react';
import img1 from '../assets/image/bengalical.png';
import img2 from '../assets/image/hindi cal.png';
import img3 from '../assets/image/kanadacal.png';
import img4 from '../assets/image/ourcal.png';
import img5 from '../assets/image/maratical.png';
import img6 from '../assets/image/malayalam cal.png';
import img7 from '../assets/image/Qibla.png';
import img8 from '../assets/image/tamilcal.png';
import img9 from '../assets/image/bhb.png';
import img10 from '../assets/image/bhp.png';
import '../assets/css/main.css'; // Import CSS file for styling

const apps = [
  { src: img1, alt: 'Bengali Calendar', link: 'https://play.google.com/store/apps/details?id=local.bengali.calendar&hl=en-IN' },
  { src: img2, alt: 'Hindi Calendar', link: 'https://play.google.com/store/apps/details?id=local.hindi.calendar&hl=en-IN' },
  { src: img3, alt: 'Kannada Calendar', link: 'https://play.google.com/store/apps/details?id=local.kannada.calendar&hl=en-IN' },
  { src: img4, alt: 'Telugu Calendar', link: 'https://play.google.com/store/apps/details?id=local.telugu.calendar&hl=en-IN' },
  { src: img5, alt: 'Marathi Calendar', link: 'https://play.google.com/store/apps/details?id=local.marathi.calendar&hl=en-IN' },
  { src: img6, alt: 'Malayalam Calendar', link: 'https://play.google.com/store/apps/details?id=local.malayalam.calendar&hl=en-IN' },
  { src: img7, alt: 'Qibla Finder', link: 'https://play.google.com/store/apps/details?id=local.urdu.calendar&hl=en-IN' },
  { src: img8, alt: 'Tamil Calendar', link: 'https://play.google.com/store/apps/details?id=local.tamil.calendar&hl=en-IN' },
  { src: img9, alt: 'Bhagavad Gita (Bengali)', link: 'https://play.google.com/store/apps/details?id=bengali.bhagavadgita&hl=en-IN' },
  { src: img10, alt: 'Bhagavad Gita (Punjabi)', link: 'https://play.google.com/store/apps/details?id=punjabi.bhagavadgita&hl=en-IN' },
];

const Portfolio = () => {
  return (
    <section id="portfolio" className="portfolio section">
      <div className="container section-title aos-init aos-animate" data-aos="fade-up">
        <h2>Portfolio</h2>
      </div>

      <div className="container">
        <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
          <ul className="portfolio-filters isotope-filters aos-init aos-animate text-center">
            <h4 data-filter=".filter-app active">Our Apps</h4>
          </ul>

          <div className="row gy-4 isotope-container aos-init aos-animate justify-content-center ">
            {apps.map((app, index) => (
              <div key={index} className="col-lg-2 col-md-4 col-sm-6 portfolio-item isotope-item filter-app ">
                <div className="portfolio-image-wrapper ">
                  <a href={app.link} target="_blank" rel="noopener noreferrer">
                    <img src={app.src} className="img-style" alt={app.alt}/>
                    <div className="overlay">
                      <div className="view-button">View</div>
                    </div>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;






