import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from './component';

function App() {
  return (
   
    <>
     <Router>
       <Routes>
         <Route path="/" element={<Index />} />
        {/* <Route path="/home" element={<Index />} />
         <Route path="/about" element={<About />} />
         <Route path="/portfolio" element={<Portfolio />} />
         <Route path="/tech-stack" element={<OurSpecialization />} /> */}
      
       </Routes>
     </Router>
    </>
  );
}

export default App;

