
  // State for form submission
  // const [formData, setFormData] = useState({
  //   name: '',
  //   email: '',
  //   subject: '',
  //   message: '',
  // });

  // const [loading, setLoading] = useState(false);
  // const [sentMessage, setSentMessage] = useState('');
  // const [errorMessage, setErrorMessage] = useState('');

  // // Handle form input changes
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // // Handle form submission
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setErrorMessage('');
  //   setSentMessage('');

  //   // Form submission logic (e.g., sending data to an API or server)
  //   try {
  //     const response = await fetch('', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       body: new URLSearchParams(formData).toString(),
  //     });

  //     if (response.ok) {
  //       setSentMessage('Your message has been sent. Thank you!');
  //     } else {
  //       throw new Error('Something went wrong');
  //     }
  //   } catch (error) {
  //     setErrorMessage('Failed to send message. Please try again.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  import React, { useState } from 'react';
  import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure you have this in your project
  
  const Contact = () => {
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const mailtoLink = `mailto:dharmendrakumararigela03@gmail.com?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(formData.message)}`;
      const newTab = window.open(mailtoLink, '_blank');
      if (newTab) {
        newTab.opener = null;
      }
  
      // Reset form data to clear the inputs
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    };
  
    return (
      <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Contact us</h2>
        </div>
  
        <div className="container" data-aos="fade" data-aos-delay="100">
          <div className="row gy-4">
            <div className="col-lg-4">
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="200">
                <i className="bi bi-geo-alt flex-shrink-0"></i>
                <div>
                  <h3>Address</h3>
                  <p>Bhramins Street, Durgi, Guntur, Andhra Pradesh - 522612</p>
                </div>
              </div>
  
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="300">
                <i className="bi bi-telephone flex-shrink-0"></i>
                <div>
                  <h3>Call Us</h3>
                  <p>+91 6300542695</p>
                </div>
              </div>
  
              <div className="info-item d-flex" data-aos="fade-up" data-aos-delay="400">
                <i className="bi bi-envelope flex-shrink-0"></i>
                <div>
                  <h3>Email Us</h3>
                  <p>dharmendrakumararigela03@gmail.com</p>
                </div>
              </div>
            </div>
  
            <div className="col-lg-8">
              <form onSubmit={handleSubmit} className="php-email-form" data-aos="fade-up" data-aos-delay="200">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
  
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
  
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
  
                  <div className="col-md-12">
                    <textarea
                      name="message"
                      className="form-control"
                      rows="6"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </div>
  
                  <div className="col-md-12 text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Contact;
  
