import React from 'react';
import '../assets/css/main.css';


const Active = () => {
    const startCall = () => {
        window.open('tel:9398977918', '_blank');
    };

    return (
        <section className="ftco-section ftco-counter img" id="section-counter">
            <div className="container" >
                {/* <div className="row justify-content-center mb-3 pb-3 text-center">
                    <div className="col-md-10">
                        <h3>We are pioneers</h3>
                        <p>Elevate your online presence with a free consultation. Schedule now to transform your concepts into reality.</p>
                        <div className="d-flex justify-content-center my-4">
                            <button className="btn btn-primary" onClick={startCall}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                                </svg> Schedule A Call
                            </button>
                        </div>
                    </div>
                </div> */}
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="30000">30000</strong>
                                        <span> &nbsp;Active Users</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="150">150 +</strong>
                                        <span>&nbsp;Qualified Apps</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="50">50 +</strong>
                                        <span>&nbsp;Qualified Games</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 d-flex justify-content-center counter-wrap ftco-animate">
                                <div className="block-18 text-center">
                                    <div className="text">
                                        <strong className="number" data-number="15000000">15 Million +</strong>
                                        <span>&nbsp;Total Downloads</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Active;
