import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css/animate.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Ensure Bootstrap JS is included


const HeroSection = () => {

  useEffect(() => {
    // Ensure Bootstrap's Carousel is available
    const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min');
    const carouselElement = document.getElementById('carouselExampleFade');
    if (carouselElement) {
      const carousel = new bootstrap.Carousel(carouselElement, {
        interval: 5000, // Set the interval to 5000ms
        ride: 'carousel' // Automatically start the carousel
      });
    }
  }, []);

  return (
    <main id="home" className="main">
      {/* Hero Section */}
      <section id="hero" className="hero section dark-background">
        <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-interval="5000" data-bs-ride="carousel">
          <div className="carousel-inner">
            {/* Slide 1 */}
            <div className="carousel-item active">
              <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">Introduction <span></span></h2>
                <p className="animate__animated animate__fadeInUp">
                Welcome to Local Developer Apps! We're passionate about turning your mobile app ideas into reality. We love creating innovative and efficient mobile solutions tailored just for you. As a top-notch mobile app development company, we excel in designing, developing, and launching custom apps that empower both businesses and individuals.
Our friendly and expert team is here to help you every step of the way, from brainstorming ideas to the final launch and beyond. We believe in building apps that are functional and delightful to use, making a real difference in your daily life or business operations.
Join us at Local Developer Apps and let’s create something amazing together!
                </p>
                {/* <a href="" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
              </div>
            </div>

            {/* Slide 2 */}
            <div className="carousel-item">
              <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown"> Where your ideas meet innovation!</h2>
                <p className="animate__animated animate__fadeInUp">
                "Local Developer Apps" is a dynamic and forward-thinking mobile app development company dedicated to transforming your app visions into reality.In just a short time, we've grown our community to over 5000 happy users and achieved thousands of downloads. We've successfully launched 8+ amazing apps that are making a real difference.
                </p>
                <a href="#about" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
              </div>
            </div>

            {/* Slide 3 */}
            <div className="carousel-item">
              <div className="carousel-container">
                <h2 className="animate__animated animate__fadeInDown">Tech Stack</h2>
                <p className="animate__animated animate__fadeInUp">
                 
We are utilizing various advanced technologies known for their speed and efficiency, such as React.js, React Native, Flutter, Laravel, HTML, CSS, JavaScript, Bootstrap, and many others. Additionally, we incorporate other relevant technologies to enhance our development process and deliver high-performance applications.
                </p>
                <a href="#techstack" className="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
              </div>
            </div>
          </div>

          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
            <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>

          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
            <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
          <defs>
            <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
          </defs>
          <g className="wave1">
            <use xlinkHref="#wave-path" x="50" y="3"></use>
          </g>
          <g className="wave2">
            <use xlinkHref="#wave-path" x="50" y="0"></use>
          </g>
          <g className="wave3">
            <use xlinkHref="#wave-path" x="50" y="9"></use>
          </g>
        </svg>
      </section>
    </main>
  );
};

export default HeroSection;



