
import React from 'react';
import Header from './header';
import HeroSection from './slider';
import About from './about';
import Portfolio from './portfolio';
import Contact from './contact';
import Footer from './footer';
import Active from './active';
import OurSpecialization from './tech stack';

function Index() {
  return (
    <>
    <Header/>
    <HeroSection/>
    <About/>
    
    <Portfolio/>
    <Active/>
    <OurSpecialization/>
    <Contact/>
    <Footer/>
  
    </>
  );
}

export default Index;